<template>
  <div class="Service">
    <div class="demo-slider-area slider-area bg-transparent slider-style-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center display-two">
              <h1
                class="title mt--130 mb--50"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                商业地产基础建设服务商
              </h1>
              <br />
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                ● 业务流式设计 ● 简单易懂 ● 操作方便
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper plr_lg--30 plr_md--30 plr_sm--0 plr-0 mb--40">
      <div class="container">
        <div class="row row--0 case">
          <div class="tabs col-3">
            <div
              class="tab"
              :class="{ active: active === 0 }"
              @click="active = 0"
              id="0"
            >
              商业综合体
            </div>
            <div
              class="tab"
              :class="{ active: active === 1 }"
              @click="active = 1"
              id="1"
            >
              商住型公寓
            </div>
            <div
              class="tab"
              :class="{ active: active === 2 }"
              @click="active = 2"
              id="2"
            >
              写字楼
            </div>
            <div
              class="tab"
              :class="{ active: active === 3 }"
              @click="active = 3"
              id="3"
            >
              智慧创业园
            </div>
          </div>
          <div class="col-1"></div>
          <div class="content col-8" :class="{ active: active === 0 }">
            <div class="title">
              <div class="concentricCircle"></div>
              <span>银泰百货（之江店）</span>
            </div>
            <div class="line"></div>
            <div class="title2"><span>·</span>项目总面积</div>
            <p class="describe">园区占地130多亩，总建筑面积1.2万余平方米。</p>
            <div class="title2"><span>·</span>项目建设特色</div>
            <p class="describe">
              商业综合体，涵盖商业办公、商场、停车场、商铺、仓库等多种房产类型。2012年5月进行智能硬件设备建设，安装了智能电表和中央空调分户计费系统，最终实现云上集成化处理，同时采购新的硬件，补足原先体系缺失的环节，在“水电能效监测系统”、“智能节电”等一系列环节做到了智能化管控，搭建了特色的物联网体系。
            </p>
          </div>
          <div class="content col-8" :class="{ active: active === 1 }">
            <div class="title">
              <div class="concentricCircle"></div>
              <span>现代野风中心</span>
            </div>
            <div class="line"></div>
            <div class="title2"><span>·</span>项目总面积</div>
            <p class="describe">
              占地面积15000平方米，建筑面积111945平方米，建筑结构：框架,砖混,剪力墙,砖木,框架剪力墙,钢结构。装修状况：精装修。
            </p>
            <div class="title2"><span>·</span>项目建设特色</div>
            <p class="describe">
              商住型公寓项目，使用房源管理、合同管理、租户入住管理等模块，施工入场智能电表、智能门锁和物联网水表等硬件设备。解决物业方房租收费困难，欠费催缴人工处理和能源使用后缴费问题。目前做到房租逾期自动锁门、能源账户预充值、日扣费。能源账单报表自动生成等解决方案。
            </p>
          </div>
          <div class="content col-8" :class="{ active: active === 2 }">
            <div class="title">
              <div class="concentricCircle"></div>
              <span>湖畔中心</span>
            </div>
            <div class="line"></div>
            <div class="title2"><span>·</span>项目总面积</div>
            <p class="describe">有写字楼、商铺、高端酒店等综合性园区。</p>
            <div class="title2"><span>·</span>项目建设特色</div>
            <p class="describe">
              写字楼项目，中央空调分户计费系统进行改造。项目从今年七月份开启第一期建设，第一期建设从软件系统建设入手，先以园区运营方运营管理手段的数字化升级为建设重点，通过数字化手段，优化了运营方对企业的管理，有效沉淀核心运营数据。目前，项目已完成二期建设相对与一期建设，项目二期从一期的软件建设升级到软硬件一体；从园区运营管理能力升级到直接惠及在园企业，为园企间搭建起“互联网高速公路”；从通过多渠道沉淀数据，整理数据到分析利用数据，建设大数据应用，有效的提升了园区能效管理能力。
            </p>
          </div>
          <div class="content col-8" :class="{ active: active === 3 }">
            <div class="title">
              <div class="concentricCircle"></div>
              <span>创想邦智慧园区</span>
            </div>
            <div class="line"></div>
            <div class="title2"><span>·</span>项目总面积</div>
            <p class="describe">
              工厂改造写字楼园区，园区内已电商产业为主。有厂房、仓库、小办公室和虚拟办公室等。
            </p>
            <div class="title2"><span>·</span>项目建设特色</div>
            <p class="describe">
              进行智能电表改造，将以前的插IC卡表改成智能计费电表。系统于2022年上半年部署上线，通过本系统的数据中心建设，提升园区管理水平，提高园区人员工作效率，节省园区运营成本，园区各类运行数据一览无余，支持自定义配置计费模式、且预缴费系统防止租户欠费欠款，能源数据自动生成报表。为领导层制定园区发展路线及调整企业布局提供数据上的决策支持。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VanheCard from "../../components/custom/VanheCard.vue";
import Title from "../../components/elements/title/Title.vue";
export default {
  components: {
    VanheCard,
    Title,
  },
  data: function () {
    return {
      active: 0,
      painList: [
        {
          id: 1,
          img: "https://images.aiwork.cloud/website/yewuyuan@2x.png",
          title: "业务员私下收租金为租客办理入住并卷款跑路",
          details: [
            "系统空置走电功能，设置每个房间每日最高用电限额（n kw·h），每日超出用电限度后电表自动跳闸，次日会自动开闸。 ",
            "财务审核流程，若未获取到钱帐一致审核信息，房租账单逾期、能源账户欠费，将跳闸、锁门禁等操作强制租户结清才可使用。",
          ],
        },
        {
          id: 2,
          img: "https://images.aiwork.cloud/website/zuhu@2x.png",
          title: "租户太多，房租账单忘记收款",
          details: [
            "合同线上签约功能，自动捕捉合同信息。每期房租付款日提前提醒租客缴费，并创建为工单提醒物业关注缴费情况。",
            "自动形成账单各状态分析报表。使物业及时关注逾期账单。",
          ],
        },
        {
          id: 3,
          img: "https://images.aiwork.cloud/website/yunyingfnag@2x.png",
          title: "运营方对入住园区的公司或个人信息保护需求",
          details: [
            "独立部署系统，园区租户信息保护功能。",
            "第三方评测机构对整套系统检测，并出据检测证书。",
          ],
        },
        {
          id: 4,
          img: "https://images.aiwork.cloud/website/xitong@2x.png",
          title: "系统建设成本",
          details: [
            "万合智联公司为园区提供增值服务功能，广告、商标品牌注册、公司注册、饮用水、绿植、共享行政财务人事等服务收益与园区运营方共享。",
            "根据园区规模进行前期硬件设备减免实施，降本增效。",
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.Service {
  background-color: #f9fbff;
  overflow: hidden;
  .demo-slider-area {
    background-image: url("https://images.aiwork.cloud/website/banner_fuwu@2x.png");
    background-size: 1920px 500px;
    background-color: #1b3f64 !important;
    background-repeat: no-repeat;
    background-position: center;
    color: #ffffff;
    margin-bottom: 40px;
    height: 500px;
    .inner {
      text-align: end !important;
      h1 {
        font-size: 1em;
        color: #ffffff !important;
      }
      .brandPositioning {
        display: inline-block;
        background-color: var(--color-primary);
        height: 50px;
        border-radius: 4px 4px 4px 4px;
        line-height: 50px;
        text-align: center;
        font-size: 0.6em;
        margin-bottom: 24px;
        padding: 0 0.66em;
      }
      .description {
        display: inline-block;
        color: #ffffff !important;
        padding: 0;
        margin-bottom: 72px;
        font-size: 0.6em;
        background: var(--color-primary);

        border-radius: 4px 4px 4px 4px;
        padding: 9px 25px;
      }
      .bookingSystemDemonstration {
        display: inline-block;
        height: 56px;
        line-height: 56px;
        border-radius: 70px 70px 70px 70px;
        border: 1px solid #ffffff;
        text-align: center;
        margin-bottom: 153px;
        font-size: 0.4em;
        padding: 0 1.3em;
      }
    }
  }
  .describeDetails {
    margin-top: 50px;
    list-style-type: none;
    li {
      font-size: 16px;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      color: #666666;
      line-height: 40px;
      span {
        font-size: 50px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  .describeDetails.desRight {
    text-align: right;
  }

  .concentricCircle {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #3776ff;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: -4px;
    &::after {
      position: absolute;
      top: 3px;
      left: 3px;
      margin: auto;
      content: "";
      width: 12px;
      height: 12px;
      background: #3776ff;
      border-radius: 6px;
      box-sizing: border-box;
    }
  }

  .case {
    flex-wrap: nowrap !important;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    // max-width: 200px;
    // min-width: 150px;
    border-radius: 6px;
    overflow: hidden;
  }

  .tabs > .tab {
    height: 85px;
    line-height: 85px;
    text-align: center;
    // padding: 0 32px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
  }

  .tab.active {
    background: #3776ff;
    color: #ffffff;
  }
  .content {
    overflow: hidden;
    // max-width: 680px;
    display: none;
  }

  .content.active {
    display: block !important;
  }

  .content > .title {
    margin-bottom: 24px;
    white-space: nowrap;
  }

  .content .title2 {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-top: 32px;
  }

  .content .describe {
    font-size: 14px;
    font-family: Microsoft YaHei-Light, Microsoft YaHei;
    font-weight: 300;
    color: #666666;
    line-height: 16px;
    margin-top: 24px;
  }

  .title2 > span {
    font-size: 30px;
    vertical-align: top;
    margin-right: 0.1em;
  }

  .content > .title > span {
    display: inline-block;
    margin-left: 8px;
    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 23px;
    margin-top: 12px;
  }
}
</style>
