<template>
  <div class="Demo">
    <!-- Start Slider Area -->
    <div class="demo-slider-area slider-area bg-transparent slider-style-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center display-two">
              <h1
                class="title mt--130 mb--50"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                VHIC·数智能效管理系统
              </h1>
              <br />
              <div
                class="brandPositioning"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                智能物联解决方案服务商
              </div>
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                ● 智慧园区 ● 智慧物业 ● 园区物联
              </p>
              <div
                class="bookingSystemDemonstration"
                data-aos="fade-up"
                data-aos-delay="250"
                @click="showDialog = true"
              >
                预约系统演示
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Our Demo -->
    <div class="rn-demo-area" id="demo">
      <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
        <div class="row">
          <div class="col-lg-12">
            <Title
              title="核心功能"
              translate="Core Functions"
              data-aos="fade-up"
            ></Title>
            <p class="col-lg-10 text-center m-auto" data-aos="fade-up">
              VHIC·数智能效管理系统“应用大数据、人工智能等前沿科技，以业务系统及智能硬件为抓手、大数据分析为引擎，打造“基础建设”“智慧物联”“企业服务”“园区大数据”四大业务体系、多种成熟数字化园区管理智慧物联服务方案，满足各种商业运营园区业务场景，
              构建智能、透明、高效的数字孪生园区。
            </p>
            <!-- <SectionTitle
              text-align="center"
              subtitle="All Demo Here"
              title="Doob All Demo."
              description="We create a business and consulting Vue.js template with 50+ elements features. <br /> 19+ demo pages, faster loading and well documentated code."
              data-aos="fade-up"
            /> -->
          </div>
        </div>
        <div class="row row--0 col-8 m-auto">
          <div
            class="col-lg-4 col-md-6 col-12 mt--40"
            v-for="(page, index) in demoPages"
            :key="index"
            data-aos="fade-up"
            :data-aos-delay="100 + index"
          >
            <div class="single-demo">
              <router-link :to="page.url">
                <div class="thumbnail">
                  <img
                    class="image-dark"
                    :src="page.image"
                    alt="Corporate images"
                  />
                  <img
                    class="image-light"
                    :src="page.imageLight"
                    alt="Corporate images"
                  />
                  <span v-if="page.badge" class="label-badge">{{
                    page.badge
                  }}</span>
                </div>
              </router-link>
              <h4 class="title">
                <router-link :to="page.url">{{ page.title }}</router-link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Our Demo -->
    <div class="rn-demo-area" id="demo2">
      <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
        <div class="row">
          <div class="col-lg-12">
            <Title
              title="服务场景"
              translate="Service Scenario"
              data-aos="fade-up"
            ></Title>
          </div>
        </div>
        <div class="row row--0 col-12" style="padding-top: 33px">
          <VanheCard
            v-for="(i, index) in sceneList"
            :i="i"
            :key="i.id"
            :index="index"
          >
            <p>
              {{ i.describe }}
            </p>
            <Button
              title="查看详情"
              class="round"
              style="width: 28%; min-width: 8em"
            ></Button>
          </VanheCard>
          <!-- <div
            class="row row--0 col-12 mb--90"
            v-for="(i, index) in sceneList"
            :key="i.id"
          >
            <img
              class="col-lg-6 col-12 order-1"
              :src="i.img"
              data-aos="fade-down"
              :data-aos-delay="100 + index"
            />
            <div
              :class="{
                'col-lg-6': true,
                'col-12': true,
                content: true,
                'order-lg-2': index % 2 === 0,
              }"
              :style="{
                backgroundImage:
                  'url(' + require(`https://images.aiwork.cloud/website/0${i.id}@2x.png`) + ')',
              }"
              data-aos="fade-up"
              :data-aos-delay="100 + index"
            >
              <h3>
                <span class="concentricCircle" v-if="index % 2 === 0" />{{
                  i.title
                }}<span
                  class="concentricCircle concentricCircle1"
                  v-if="index % 2 === 1"
                />
              </h3>
              <p>
                {{ i.describe }}
              </p>
              <Button
                title="查看详情"
                class="round"
                style="width: 28%; min-width: 8em"
              ></Button>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="rn-demo-area" id="demo3">
      <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
        <div class="row">
          <div class="col-lg-12">
            <Title
              title="合作客户"
              translate="Cooperative Customers"
              data-aos="fade-up"
            ></Title>
          </div>
        </div>
        <div class="row row--0 col-12">
          <div
            class="col-lg-3 col-md-6 col-12"
            v-for="(i, index) in partnerList"
            :key="i.title"
            style="padding: 35px 33px"
            data-aos="fade-up"
            :data-aos-delay="100 + index"
          >
            <div
              class="client"
              :style="{
                backgroundImage:
                  'url(' +
                  `https://images.aiwork.cloud/website/${i.img}@2x.png` +
                  ')',
              }"
            >
              {{ i.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rn-demo-area display-two" id="demo4">
      <p class="bottomtitle" data-aos="fade-up">VHIC·数智能效管理系统</p>
      <p class="bottomdescribe" data-aos="fade-up" :data-aos-delay="100">
        智慧园区解决方案服务商
      </p>
      <div class="bottomButton" data-aos="fade-up" :data-aos-delay="150">
        <div class="contactUs">
          <a class="telNumber" href="tel:0571-86825558">0571-86825558</a>
          <div class="telTitle">联系我们</div>
        </div>
        <div class="appointmentButton" @click="showDialog = true">
          预约系统演示
        </div>
      </div>
    </div>
    <Dialog :show.sync="showDialog"></Dialog>
  </div>
</template>

<script>
import Layout from "../components/common/Layout";
import Icon from "../components/icon/Icon";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import Title from "../components/elements/title/Title";
import Button from "../components/elements/button/Button";
import VanheCard from "../components/custom/VanheCard";
import Dialog from "../components/custom/Dialog.vue";

export default {
  name: "Demo",
  components: {
    Icon,
    Layout,
    Separator,
    SectionTitle,
    Title,
    Button,
    VanheCard,
    Dialog,
  },
  data() {
    return {
      showDialog: false,
      demoPages: [
        {
          url: "/business-consulting",
          image: "https://images.aiwork.cloud/website/zhineng@2x.png",
          imageLight: "https://images.aiwork.cloud/website/zhineng@2x.png",
          title: "能源物联网服务",
        },
        {
          url: "/business-consulting",
          image: "https://images.aiwork.cloud/website/nengyuan@2x.png",
          imageLight: "https://images.aiwork.cloud/website/nengyuan@2x.png",
          title: "智能感知系统",
        },
        {
          url: "/business-consulting",
          image: "https://images.aiwork.cloud/website/zhihui@2x.png",
          imageLight: "https://images.aiwork.cloud/website/zhihui@2x.png",
          title: "智慧物业管理",
        },
        {
          url: "/business-consulting",
          image: "https://images.aiwork.cloud/website/qiye@2x.png",
          imageLight: "https://images.aiwork.cloud/website/qiye@2x.png",
          title: "企业成长分析",
        },
        {
          url: "/business-consulting",
          image: "https://images.aiwork.cloud/website/yujiao@2x.png",
          imageLight: "https://images.aiwork.cloud/website/yujiao@2x.png",
          title: "预交费系统",
        },
        {
          url: "/business-consulting",
          image: "https://images.aiwork.cloud/website/zengzhi@2x.png",
          imageLight: "https://images.aiwork.cloud/website/zengzhi@2x.png",
          title: "增值服务共享",
        },
        {
          url: "/business-consulting",
          image: "https://images.aiwork.cloud/website/yuanqu@2x.png",
          imageLight: "https://images.aiwork.cloud/website/yuanqu@2x.png",
          title: "园区AR沙盘",
        },
        {
          url: "/business-consulting",
          image: "https://images.aiwork.cloud/website/caiwu@2x.png",
          imageLight: "https://images.aiwork.cloud/website/caiwu@2x.png",
          title: "财务管理系统",
        },
        {
          url: "/business-consulting",
          image: "https://images.aiwork.cloud/website/yewu@2x.png",
          imageLight: "https://images.aiwork.cloud/website/yewu@2x.png",
          title: "业务流操作系统",
        },
      ],
      sceneList: [
        {
          id: "1",
          img: "https://images.aiwork.cloud/website/gongyu@2x.png",
          title: "商用公寓",
          describe:
            "商住型公寓自动化管理功能，电、空调、水预缴费、欠费自动锁门，账户预警通知、断水断电等事件通知。",
        },
        {
          id: "2",
          img: "https://images.aiwork.cloud/website/zongheti@2x.png",
          title: "商业综合体",
          describe:
            "多种计费模式应对商业综合体内各种房源类型的能源计费需求。多种物联网设备应用于能源数据实时采集、房源管理入住、门禁道闸、停车服务等应用场景。",
        },
        {
          id: "3",
          img: "https://images.aiwork.cloud/website/xiezilou@2x.png",
          title: "写字楼",
          describe:
            "写字楼能源管理、门禁管理、识别打卡出勤服务、共享合作服务、为公司成员提供增值服务盈利共享，园区公司成长型数据分析。",
        },
        {
          id: "4",
          img: "https://images.aiwork.cloud/website/shangpu@2x.png",
          title: "商铺",
          describe: "商铺水、电、空调缴费管理。商铺欠费门禁管理。",
        },
        {
          id: "5",
          img: "https://images.aiwork.cloud/website/changfang@2x.png",
          title: "厂房",
          describe: "厂房能源分析、碳排放数据、智能化节点，工厂成长性分析。",
        },
        {
          id: "6",
          img: "https://images.aiwork.cloud/website/cangku@2x.png",
          title: "仓库",
          describe: "仓库远程监控、自动化管理、空置走电设置，仓库进出数据。",
        },
      ],
      partnerList: [
        {
          img: "chuangxiangbang",
          title: "创想邦物业管理",
        },
        {
          img: "heda",
          title: "和达物业管理",
        },
        {
          img: "xiandai",
          title: "现代野风中心",
        },
        {
          img: "yintai",
          title: "银泰百货之江店",
        },
        {
          img: "gelai",
          title: "格莱特森智创园",
        },
        {
          img: "jingang",
          title: "南京金港科创园",
        },
        {
          img: "qiantang",
          title: "钱塘生态公园",
        },
        {
          img: "yongyu",
          title: "永裕物业管理",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.Demo {
  position: relative;
}
.demo-slider-area {
  background-image: url("https://images.aiwork.cloud/website/banner_shouye@2x.png");
  background-size: 1920px 700px;
  background-color: #675f69 !important;
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;
  .inner {
    text-align: end !important;
    h1 {
      font-size: 1em;
      color: #ffffff !important;
    }
    .brandPositioning {
      display: inline-block;
      background-color: var(--color-primary);
      height: 50px;
      border-radius: 4px 4px 4px 4px;
      line-height: 50px;
      text-align: center;
      font-size: 0.6em;
      margin-bottom: 24px;
      padding: 0 0.66em;
    }
    .description {
      color: #ffffff !important;
      padding: 0;
      margin-bottom: 72px;
      font-size: 0.6em;
    }
    .bookingSystemDemonstration {
      cursor: pointer;
      display: inline-block;
      height: 56px;
      line-height: 56px;
      border-radius: 70px 70px 70px 70px;
      border: 1px solid #ffffff;
      text-align: center;
      margin-bottom: 153px;
      font-size: 0.4em;
      padding: 0 1.3em;
    }
  }
}
#demo {
  p {
    color: #666666;
  }
}
#demo2 {
  background-color: #f9fbff;
  p {
    color: #666666;
  }
  .content {
    background-size: 50%;
    background-repeat: no-repeat;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 5%;
    justify-content: space-around;

    align-items: flex-end;

    h3 {
      font-size: 24px;
      font-weight: 400;
      color: #000000;
      line-height: 28px;
    }

    .concentricCircle {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid var(--color-primary);
      vertical-align: middle;
      border-radius: 10px;
      box-sizing: border-box;
      margin-right: 10px;

      position: relative;
    }

    .concentricCircle1 {
      margin-right: 0;
      margin-left: 10px;
    }
    .concentricCircle::after {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: var(--color-primary);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
    }
  }

  .order-lg-2 {
    background-position: 100% 0%;
    align-items: flex-start;
  }
}

#demo3 {
  background: #ffffff;
  .client {
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 120px;
    line-height: 120px;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    white-space: nowrap;
  }
}

#demo4 {
  background-color: var(--color-primary);
  min-height: 380px;
  width: 100%;
  background-image: url("https://images.aiwork.cloud/website/wenli_dibu@2x.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 70px;
  box-sizing: border-box;

  .bottomtitle {
    font-size: 1em;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 1em;
    text-align: center;
  }
  .bottomdescribe {
    font-size: 0.6em;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 0.6em;
    text-align: center;
    margin-top: 0.6em;
  }
  .bottomButton {
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    .appointmentButton {
      cursor: pointer;
      display: inline-block;
      height: calc(1.7em / 0.6);
      line-height: calc(1.7em / 0.6);
      border-radius: calc(0.85em / 0.6);
      box-sizing: border-box;
      border: #ffffff 1px solid;
      color: #ffffff;
      font-size: 0.6em;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      padding: 0 calc(1.2em / 0.6);
      margin: 0 calc(1.2em / 0.6);
      cursor: pointer;
    }
  }
  .bottomButton > * {
    vertical-align: top;
    margin-bottom: 0.5em;
  }
  .contactUs {
    height: 1.7em;
    line-height: 1.7em;
    border-radius: 0.85em;
    border: 1px solid #ffffff;
    display: inline-flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    .telNumber {
      padding: 0 1.25em;
      color: #ffffff;
      font-size: 0.85em;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      height: 100%;
      line-height: 200%;
    }
    .telTitle {
      text-align: center;
      background-color: #ffffff;
      color: #3776ff;
      padding: 0 1.8em;
      cursor: pointer;
      font-size: 0.6em;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #3776ff;
      box-sizing: border-box;
      height: 100%;
      line-height: 250%;
      border-radius: 1.5em;
    }
  }
}
</style>
