<template>
  <div>
    <!-- Start Footer Area  -->
    <footer
      v-if="data.showFooter"
      class="rn-footer footer-style-default variation-two"
    >
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="rn-footer-component">
                <img
                  class="col-4"
                  src="https://images.aiwork.cloud/website/logo2@2x.png"
                  alt=""
                />
                <p>万合智联（浙江）科技有限公司</p>
                <p class="dynamicyear">Copyright ©{{ years }} VanHe</p>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-12">
              <div class="rn-footer-widget">
                <div class="widget-menu-top">
                  <h4 class="title">快速链接</h4>
                  <div class="inner">
                    <ul class="footer-link link-hover">
                      <li>
                        <router-link to="/">首页</router-link>
                      </li>
                      <li>
                        <router-link to="/MarketPainPoints"
                          >市场痛点</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/Product">产品与解决方案</router-link>
                      </li>
                      <li>
                        <router-link to="/Service">服务案例</router-link>
                      </li>
                      <li>
                        <router-link to="/AboutUs">关于我们</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-12">
              <div class="rn-footer-widget">
                <h4 class="title">联系我们</h4>
                <div class="inner">
                  <ul class="footer-link link-hover">
                    <li>
                      <a href="mailto:vanhebiz@163.com"
                        >邮箱：vanhebiz@163.com</a
                      >
                    </li>
                    <li>
                      <a href="tel:0571-86825558">电话：0571-86825558</a>
                    </li>
                  </ul>
                </div>
                <h4 class="title">企业地址</h4>
                <div class="inner">
                  <ul class="footer-link link-hover">
                    <li>
                      <a
                        href="https://ditu.amap.com/place/B0FFM1G09V"
                        target="_blank"
                        >浙江省杭州市钱塘区下沙街道湖畔中心A座19楼</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="qrcodes">
                <div class="qrcode">
                  <img
                    src="https://images.aiwork.cloud/website/gongzhonghao@2x.png"
                    alt="爱办云公众号二维码"
                  />
                  <span>微信公众号</span>
                </div>
                <div class="qrcode">
                  <img
                    src="https://images.aiwork.cloud/website/erweima@2x.png"
                    alt="爱办云市场总监微信二维码"
                  />
                  <span>市场总监微信二维码</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter v-if="data.showNewsletter" />
    </footer>
    <!-- End Footer Area  -->
    <ScrollTop />
  </div>
</template>

<script>
import Copyright from "./Copyright";
import Button from "../../elements/button/Button";
import Icon from "../../icon/Icon";
import ScrollTop from "./ScrollTop";
import Logo from "../../elements/logo/Logo";
import Newsletter from "./Newsletter";

const date = new Date();
export default {
  name: "Footer",
  components: { Newsletter, Logo, ScrollTop, Icon, Button, Copyright },
  props: {
    data: {
      default: null,
    },
  },
  computed: {
    years() {
      return date.getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.rn-footer-component {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  img {
    margin-bottom: 32px;
  }
  p {
    margin-bottom: 11px;
  }
}
.qrcodes {
  display: flex;
  height: 100%;
  align-items: center;
  .qrcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 60px;

    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    img {
      width: 90px;
      height: 90px;
      margin-bottom: 10px;
    }
  }
}
</style>
