<template>
  <div class="secondaryTitle">
    <div class="line">
      <div class="innerCircle" style="right: 0"></div>
      <div class="outerCircle" style="right: -4px"></div>
    </div>
    <h2>{{ title }}</h2>
    <div class="line">
      <div class="innerCircle" style="left: 0"></div>
      <div class="outerCircle" style="left: -4px"></div>
    </div>
    <p>{{ translate }}</p>
  </div>
</template>

<script>
export default {
  name: "Team",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    translate: {
      type: String,
      default: "title",
    },
  },
};
</script>
<style lang="scss" scoped>
.secondaryTitle {
  text-align: center;
  vertical-align: middle;
  margin-top: 60px;
  white-space: nowrap;
  p {
    font-size: 24px;
    font-weight: 400;
    color: #3776ff;
  }
  * {
    vertical-align: middle;
  }
  h2 {
    display: inline-block;
    margin: 0 38px;
    font-size: 32px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 38px;
  }
  .line {
    display: inline-block;
    width: 8vw;
    height: 2px;
    background: #3776ff;
    border-radius: 2px;
    position: relative;
    .innerCircle {
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #3776ff;
      border-radius: 3px;
      position: absolute;
      box-sizing: border-box;
      top: -2px;
    }
    .outerCircle {
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 7px;
      position: absolute;
      box-sizing: border-box;
      border: #3776ff 1px solid;
      top: -6px;
    }
    p {
      font-size: 24px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #3776ff;
      line-height: 28px;
      margin-top: 4px;
      margin-bottom: 20px;
    }
  }
}
</style>
