<template>
  <Layout>
    <router-view />
  </Layout>
</template>

<script>
import Layout from "../../components/common/Layout.vue";

export default {
  name: "Demo",
  components: { Layout },
  data() {
    return {};
  },
};
</script>