const cityData = [{
	value: '110000',
	text: '北京市',
	children: [{
		value: "110101",
		text: "东城区"
	}, {
		value: "110102",
		text: "西城区"
	}, {
		value: "110103",
		text: "崇文区"
	}, {
		value: "110104",
		text: "宣武区"
	}, {
		value: "110105",
		text: "朝阳区"
	}, {
		value: "110106",
		text: "丰台区"
	}, {
		value: "110107",
		text: "石景山区"
	}, {
		value: "110108",
		text: "海淀区"
	}, {
		value: "110109",
		text: "门头沟区"
	}, {
		value: "110111",
		text: "房山区"
	}, {
		value: "110112",
		text: "通州区"
	}, {
		value: "110113",
		text: "顺义区"
	}, {
		value: "110114",
		text: "昌平区"
	}, {
		value: "110115",
		text: "大兴区"
	}, {
		value: "110116",
		text: "怀柔区"
	}, {
		value: "110117",
		text: "平谷区"
	}, {
		value: "110228",
		text: "密云县"
	}, {
		value: "110229",
		text: "延庆县"
	}, {
		value: "110230",
		text: "其它区"
	}]
}, {
	value: '120000',
	text: '天津市',
	children: [{
		value: "120101",
		text: "和平区"
	}, {
		value: "120102",
		text: "河东区"
	}, {
		value: "120103",
		text: "河西区"
	}, {
		value: "120104",
		text: "南开区"
	}, {
		value: "120105",
		text: "河北区"
	}, {
		value: "120106",
		text: "红桥区"
	}, {
		value: "120107",
		text: "塘沽区"
	}, {
		value: "120108",
		text: "汉沽区"
	}, {
		value: "120109",
		text: "大港区"
	}, {
		value: "120110",
		text: "东丽区"
	}, {
		value: "120111",
		text: "西青区"
	}, {
		value: "120112",
		text: "津南区"
	}, {
		value: "120113",
		text: "北辰区"
	}, {
		value: "120114",
		text: "武清区"
	}, {
		value: "120115",
		text: "宝坻区"
	}, {
		value: "120116",
		text: "滨海新区"
	}, {
		value: "120221",
		text: "宁河县"
	}, {
		value: "120223",
		text: "静海县"
	}, {
		value: "120225",
		text: "蓟县"
	}, {
		value: "120226",
		text: "其它区"
	}]
}, {
	value: '130000',
	text: '河北省',
	children: [{
		value: "130100",
		text: "石家庄市"
	}, {
		value: "130200",
		text: "唐山市"
	}, {
		value: "130300",
		text: "秦皇岛市"
	}, {
		value: "130400",
		text: "邯郸市"
	}, {
		value: "130500",
		text: "邢台市"
	}, {
		value: "130600",
		text: "保定市"
	}, {
		value: "130700",
		text: "张家口市"
	}, {
		value: "130800",
		text: "承德市"
	}, {
		value: "130900",
		text: "沧州市"
	}, {
		value: "131000",
		text: "廊坊市"
	}, {
		value: "131100",
		text: "衡水市"
	}]
}, {
	value: '140000',
	text: '山西省',
	children: [{
		value: "140100",
		text: "太原市"
	}, {
		value: "140200",
		text: "大同市"
	}, {
		value: "140300",
		text: "阳泉市"
	}, {
		value: "140400",
		text: "长治市"
	}, {
		value: "140500",
		text: "晋城市"
	}, {
		value: "140600",
		text: "朔州市"
	}, {
		value: "140700",
		text: "晋中市"
	}, {
		value: "140800",
		text: "运城市"
	}, {
		value: "140900",
		text: "忻州市"
	}, {
		value: "141000",
		text: "临汾市"
	}, {
		value: "141100",
		text: "吕梁市"
	}]
}, {
	value: '150000',
	text: '内蒙古',
	children: [{
		value: "150100",
		text: "呼和浩特市"
	}, {
		value: "150200",
		text: "包头市"
	}, {
		value: "150300",
		text: "乌海市"
	}, {
		value: "150400",
		text: "赤峰市"
	}, {
		value: "150500",
		text: "通辽市"
	}, {
		value: "150600",
		text: "鄂尔多斯市"
	}, {
		value: "150700",
		text: "呼伦贝尔市"
	}, {
		value: "150800",
		text: "巴彦淖尔市"
	}, {
		value: "150900",
		text: "乌兰察布市"
	}, {
		value: "152200",
		text: "兴安盟"
	}, {
		value: "152500",
		text: "锡林郭勒盟"
	}, {
		value: "152900",
		text: "阿拉善盟"
	}]
}, {
	value: '210000',
	text: '辽宁省',
	children: [{
		value: "210100",
		text: "沈阳市"
	}, {
		value: "210200",
		text: "大连市"
	}, {
		value: "210300",
		text: "鞍山市"
	}, {
		value: "210400",
		text: "抚顺市"
	}, {
		value: "210500",
		text: "本溪市"
	}, {
		value: "210600",
		text: "丹东市"
	}, {
		value: "210700",
		text: "锦州市"
	}, {
		value: "210800",
		text: "营口市"
	}, {
		value: "210900",
		text: "阜新市"
	}, {
		value: "211000",
		text: "辽阳市"
	}, {
		value: "211100",
		text: "盘锦市"
	}, {
		value: "211200",
		text: "铁岭市"
	}, {
		value: "211300",
		text: "朝阳市"
	}, {
		value: "211400",
		text: "葫芦岛市"
	}]
}, {
	value: '220000',
	text: '吉林省',
	children: [{
		value: "220100",
		text: "长春市"
	}, {
		value: "220200",
		text: "吉林市"
	}, {
		value: "220300",
		text: "四平市"
	}, {
		value: "220400",
		text: "辽源市"
	}, {
		value: "220500",
		text: "通化市"
	}, {
		value: "220600",
		text: "白山市"
	}, {
		value: "220700",
		text: "松原市"
	}, {
		value: "220800",
		text: "白城市"
	}, {
		value: "222400",
		text: "延边朝鲜族自治州"
	}]
}, {
	value: '230000',
	text: '黑龙江省',
	children: [{
		value: "230100",
		text: "哈尔滨市"
	}, {
		value: "230200",
		text: "齐齐哈尔市"
	}, {
		value: "230300",
		text: "鸡西市"
	}, {
		value: "230400",
		text: "鹤岗市"
	}, {
		value: "230500",
		text: "双鸭山市"
	}, {
		value: "230600",
		text: "大庆市"
	}, {
		value: "230700",
		text: "伊春市"
	}, {
		value: "230800",
		text: "佳木斯市"
	}, {
		value: "230900",
		text: "七台河市"
	}, {
		value: "231000",
		text: "牡丹江市"
	}, {
		value: "231100",
		text: "黑河市"
	}, {
		value: "231200",
		text: "绥化市"
	}, {
		value: "232700",
		text: "大兴安岭地区"
	}]
}, {
	value: '310000',
	text: '上海市',
	children: [{
		value: "310101",
		text: "黄浦区"
	}, {
		value: "310103",
		text: "卢湾区"
	}, {
		value: "310104",
		text: "徐汇区"
	}, {
		value: "310105",
		text: "长宁区"
	}, {
		value: "310106",
		text: "静安区"
	}, {
		value: "310107",
		text: "普陀区"
	}, {
		value: "310108",
		text: "闸北区"
	}, {
		value: "310109",
		text: "虹口区"
	}, {
		value: "310110",
		text: "杨浦区"
	}, {
		value: "310112",
		text: "闵行区"
	}, {
		value: "310113",
		text: "宝山区"
	}, {
		value: "310114",
		text: "嘉定区"
	}, {
		value: "310115",
		text: "浦东新区"
	}, {
		value: "310116",
		text: "金山区"
	}, {
		value: "310117",
		text: "松江区"
	}, {
		value: "310118",
		text: "青浦区"
	}, {
		value: "310119",
		text: "南汇区"
	}, {
		value: "310120",
		text: "奉贤区"
	}, {
		value: "310152",
		text: "川沙区"
	}, {
		value: "310230",
		text: "崇明县"
	}, {
		value: "310231",
		text: "其它区"
	}]
}, {
	value: '320000',
	text: '江苏省',
	children: [{
		value: "320100",
		text: "南京市"
	}, {
		value: "320200",
		text: "无锡市"
	}, {
		value: "320300",
		text: "徐州市"
	}, {
		value: "320400",
		text: "常州市"
	}, {
		value: "320500",
		text: "苏州市"
	}, {
		value: "320600",
		text: "南通市"
	}, {
		value: "320700",
		text: "连云港市"
	}, {
		value: "320800",
		text: "淮安市"
	}, {
		value: "320900",
		text: "盐城市"
	}, {
		value: "321000",
		text: "扬州市"
	}, {
		value: "321100",
		text: "镇江市"
	}, {
		value: "321200",
		text: "泰州市"
	}, {
		value: "321300",
		text: "宿迁市"
	}]
}, {
	value: '330000',
	text: '浙江省',
	children: [{
		value: "330100",
		text: "杭州市"
	}, {
		value: "330200",
		text: "宁波市"
	}, {
		value: "330300",
		text: "温州市"
	}, {
		value: "330400",
		text: "嘉兴市"
	}, {
		value: "330500",
		text: "湖州市"
	}, {
		value: "330600",
		text: "绍兴市"
	}, {
		value: "330700",
		text: "金华市"
	}, {
		value: "330800",
		text: "衢州市"
	}, {
		value: "330900",
		text: "舟山市"
	}, {
		value: "331000",
		text: "台州市"
	}, {
		value: "331100",
		text: "丽水市"
	}]
}, {
	value: '340000',
	text: '安徽省',
	children: [{
		value: "340100",
		text: "合肥市"
	}, {
		value: "340200",
		text: "芜湖市"
	}, {
		value: "340300",
		text: "蚌埠市"
	}, {
		value: "340400",
		text: "淮南市"
	}, {
		value: "340500",
		text: "马鞍山市"
	}, {
		value: "340600",
		text: "淮北市"
	}, {
		value: "340700",
		text: "铜陵市"
	}, {
		value: "340800",
		text: "安庆市"
	}, {
		value: "341000",
		text: "黄山市"
	}, {
		value: "341100",
		text: "滁州市"
	}, {
		value: "341200",
		text: "阜阳市"
	}, {
		value: "341300",
		text: "宿州市"
	}, {
		value: "341500",
		text: "六安市"
	}, {
		value: "341600",
		text: "亳州市"
	}, {
		value: "341700",
		text: "池州市"
	}, {
		value: "341800",
		text: "宣城市"
	}]
}, {
	value: '350000',
	text: '福建省',
	children: [{
		value: "350100",
		text: "福州市"
	}, {
		value: "350200",
		text: "厦门市"
	}, {
		value: "350300",
		text: "莆田市"
	}, {
		value: "350400",
		text: "三明市"
	}, {
		value: "350500",
		text: "泉州市"
	}, {
		value: "350600",
		text: "漳州市"
	}, {
		value: "350700",
		text: "南平市"
	}, {
		value: "350800",
		text: "龙岩市"
	}, {
		value: "350900",
		text: "宁德市"
	}]
}, {
	value: '360000',
	text: '江西省',
	children: [{
		value: "360100",
		text: "南昌市"
	}, {
		value: "360200",
		text: "景德镇市"
	}, {
		value: "360300",
		text: "萍乡市"
	}, {
		value: "360400",
		text: "九江市"
	}, {
		value: "360500",
		text: "新余市"
	}, {
		value: "360600",
		text: "鹰潭市"
	}, {
		value: "360700",
		text: "赣州市"
	}, {
		value: "360800",
		text: "吉安市"
	}, {
		value: "360900",
		text: "宜春市"
	}, {
		value: "361000",
		text: "抚州市"
	}, {
		value: "361100",
		text: "上饶市"
	}]
}, {
	value: '370000',
	text: '山东省',
	children: [{
		value: "370100",
		text: "济南市"
	}, {
		value: "370200",
		text: "青岛市"
	}, {
		value: "370300",
		text: "淄博市"
	}, {
		value: "370400",
		text: "枣庄市"
	}, {
		value: "370500",
		text: "东营市"
	}, {
		value: "370600",
		text: "烟台市"
	}, {
		value: "370700",
		text: "潍坊市"
	}, {
		value: "370800",
		text: "济宁市"
	}, {
		value: "370900",
		text: "泰安市"
	}, {
		value: "371000",
		text: "威海市"
	}, {
		value: "371100",
		text: "日照市"
	}, {
		value: "371200",
		text: "莱芜市"
	}, {
		value: "371300",
		text: "临沂市"
	}, {
		value: "371400",
		text: "德州市"
	}, {
		value: "371500",
		text: "聊城市"
	}, {
		value: "371600",
		text: "滨州市"
	}, {
		value: "371700",
		text: "菏泽市"
	}]
}, {
	value: '410000',
	text: '河南省',
	children: [{
		value: "410100",
		text: "郑州市"
	}, {
		value: "410200",
		text: "开封市"
	}, {
		value: "410300",
		text: "洛阳市"
	}, {
		value: "410400",
		text: "平顶山市"
	}, {
		value: "410500",
		text: "安阳市"
	}, {
		value: "410600",
		text: "鹤壁市"
	}, {
		value: "410700",
		text: "新乡市"
	}, {
		value: "410800",
		text: "焦作市"
	}, {
		value: "410881",
		text: "济源市"
	}, {
		value: "410900",
		text: "濮阳市"
	}, {
		value: "411000",
		text: "许昌市"
	}, {
		value: "411100",
		text: "漯河市"
	}, {
		value: "411200",
		text: "三门峡市"
	}, {
		value: "411300",
		text: "南阳市"
	}, {
		value: "411400",
		text: "商丘市"
	}, {
		value: "411500",
		text: "信阳市"
	}, {
		value: "411600",
		text: "周口市"
	}, {
		value: "411700",
		text: "驻马店市"
	}]
}, {
	value: '420000',
	text: '湖北省',
	children: [{
		value: "420100",
		text: "武汉市"
	}, {
		value: "420200",
		text: "黄石市"
	}, {
		value: "420300",
		text: "十堰市"
	}, {
		value: "420500",
		text: "宜昌市"
	}, {
		value: "420600",
		text: "襄阳市"
	}, {
		value: "420700",
		text: "鄂州市"
	}, {
		value: "420800",
		text: "荆门市"
	}, {
		value: "420900",
		text: "孝感市"
	}, {
		value: "421000",
		text: "荆州市"
	}, {
		value: "421100",
		text: "黄冈市"
	}, {
		value: "421200",
		text: "咸宁市"
	}, {
		value: "421300",
		text: "随州市"
	}, {
		value: "422800",
		text: "恩施土家族苗族自治州"
	}, {
		value: "429004",
		text: "仙桃市"
	}, {
		value: "429005",
		text: "潜江市"
	}, {
		value: "429006",
		text: "天门市"
	}, {
		value: "429021",
		text: "神农架林区"
	}]
}, {
	value: '430000',
	text: '湖南省',
	children: [{
		value: "430100",
		text: "长沙市"
	}, {
		value: "430200",
		text: "株洲市"
	}, {
		value: "430300",
		text: "湘潭市"
	}, {
		value: "430400",
		text: "衡阳市"
	}, {
		value: "430500",
		text: "邵阳市"
	}, {
		value: "430600",
		text: "岳阳市"
	}, {
		value: "430700",
		text: "常德市"
	}, {
		value: "430800",
		text: "张家界市"
	}, {
		value: "430900",
		text: "益阳市"
	}, {
		value: "431000",
		text: "郴州市"
	}, {
		value: "431100",
		text: "永州市"
	}, {
		value: "431200",
		text: "怀化市"
	}, {
		value: "431300",
		text: "娄底市"
	}, {
		value: "433100",
		text: "湘西土家族苗族自治州"
	}]
}, {
	value: '440000',
	text: '广东省',
	children: [{
		value: "440100",
		text: "广州市"
	}, {
		value: "440200",
		text: "韶关市"
	}, {
		value: "440300",
		text: "深圳市"
	}, {
		value: "440400",
		text: "珠海市"
	}, {
		value: "440500",
		text: "汕头市"
	}, {
		value: "440600",
		text: "佛山市"
	}, {
		value: "440700",
		text: "江门市"
	}, {
		value: "440800",
		text: "湛江市"
	}, {
		value: "440900",
		text: "茂名市"
	}, {
		value: "441200",
		text: "肇庆市"
	}, {
		value: "441300",
		text: "惠州市"
	}, {
		value: "441400",
		text: "梅州市"
	}, {
		value: "441500",
		text: "汕尾市"
	}, {
		value: "441600",
		text: "河源市"
	}, {
		value: "441700",
		text: "阳江市"
	}, {
		value: "441800",
		text: "清远市"
	}, {
		value: "441900",
		text: "东莞市"
	}, {
		value: "442000",
		text: "中山市"
	}, {
		value: "445100",
		text: "潮州市"
	}, {
		value: "445200",
		text: "揭阳市"
	}, {
		value: "445300",
		text: "云浮市"
	}]
}, {
	value: '450000',
	text: '广西壮族',
	children: [{
		value: "450100",
		text: "南宁市"
	}, {
		value: "450200",
		text: "柳州市"
	}, {
		value: "450300",
		text: "桂林市"
	}, {
		value: "450400",
		text: "梧州市"
	}, {
		value: "450500",
		text: "北海市"
	}, {
		value: "450600",
		text: "防城港市"
	}, {
		value: "450700",
		text: "钦州市"
	}, {
		value: "450800",
		text: "贵港市"
	}, {
		value: "450900",
		text: "玉林市"
	}, {
		value: "451000",
		text: "百色市"
	}, {
		value: "451100",
		text: "贺州市"
	}, {
		value: "451200",
		text: "河池市"
	}, {
		value: "451300",
		text: "来宾市"
	}, {
		value: "451400",
		text: "崇左市"
	}]
}, {
	value: '460000',
	text: '海南省',
	children: [{
		value: "460100",
		text: "海口市"
	}, {
		value: "460200",
		text: "三亚市"
	}, {
		value: "469001",
		text: "五指山市"
	}, {
		value: "469002",
		text: "琼海市"
	}, {
		value: "469003",
		text: "儋州市"
	}, {
		value: "469005",
		text: "文昌市"
	}, {
		value: "469006",
		text: "万宁市"
	}, {
		value: "469007",
		text: "东方市"
	}, {
		value: "469025",
		text: "定安县"
	}, {
		value: "469026",
		text: "屯昌县"
	}, {
		value: "469027",
		text: "澄迈县"
	}, {
		value: "469028",
		text: "临高县"
	}, {
		value: "469030",
		text: "白沙黎族自治县"
	}, {
		value: "469031",
		text: "昌江黎族自治县"
	}, {
		value: "469033",
		text: "乐东黎族自治县"
	}, {
		value: "469034",
		text: "陵水黎族自治县"
	}, {
		value: "469035",
		text: "保亭黎族苗族自治县"
	}, {
		value: "469036",
		text: "琼中黎族苗族自治县"
	}, {
		value: "469037",
		text: "西沙群岛"
	}, {
		value: "469038",
		text: "南沙群岛"
	}, {
		value: "469039",
		text: "中沙群岛的岛礁及其海域"
	}]
}, {
	value: '500000',
	text: '重庆',
	children: [{
		value: "500101",
		text: "万州区"
	}, {
		value: "500102",
		text: "涪陵区"
	}, {
		value: "500103",
		text: "渝中区"
	}, {
		value: "500104",
		text: "大渡口区"
	}, {
		value: "500105",
		text: "江北区"
	}, {
		value: "500106",
		text: "沙坪坝区"
	}, {
		value: "500107",
		text: "九龙坡区"
	}, {
		value: "500108",
		text: "南岸区"
	}, {
		value: "500109",
		text: "北碚区"
	}, {
		value: "500110",
		text: "万盛区"
	}, {
		value: "500111",
		text: "双桥区"
	}, {
		value: "500112",
		text: "渝北区"
	}, {
		value: "500113",
		text: "巴南区"
	}, {
		value: "500114",
		text: "黔江区"
	}, {
		value: "500115",
		text: "长寿区"
	}, {
		value: "500222",
		text: "綦江县"
	}, {
		value: "500223",
		text: "潼南县"
	}, {
		value: "500224",
		text: "铜梁县"
	}, {
		value: "500225",
		text: "大足县"
	}, {
		value: "500226",
		text: "荣昌县"
	}, {
		value: "500227",
		text: "璧山县"
	}, {
		value: "500228",
		text: "梁平县"
	}, {
		value: "500229",
		text: "城口县"
	}, {
		value: "500230",
		text: "丰都县"
	}, {
		value: "500231",
		text: "垫江县"
	}, {
		value: "500232",
		text: "武隆县"
	}, {
		value: "500233",
		text: "忠县"
	}, {
		value: "500234",
		text: "开县"
	}, {
		value: "500235",
		text: "云阳县"
	}, {
		value: "500236",
		text: "奉节县"
	}, {
		value: "500237",
		text: "巫山县"
	}, {
		value: "500238",
		text: "巫溪县"
	}, {
		value: "500240",
		text: "石柱土家族自治县"
	}, {
		value: "500241",
		text: "秀山土家族苗族自治县"
	}, {
		value: "500242",
		text: "酉阳土家族苗族自治县"
	}, {
		value: "500243",
		text: "彭水苗族土家族自治县"
	}, {
		value: "500381",
		text: "江津区"
	}, {
		value: "500382",
		text: "合川区"
	}, {
		value: "500383",
		text: "永川区"
	}, {
		value: "500384",
		text: "南川区"
	}, {
		value: "500385",
		text: "其它区"
	}]
}, {
	value: '510000',
	text: '四川省',
	children: [{
		value: "510100",
		text: "成都市"
	}, {
		value: "510300",
		text: "自贡市"
	}, {
		value: "510400",
		text: "攀枝花市"
	}, {
		value: "510500",
		text: "泸州市"
	}, {
		value: "510600",
		text: "德阳市"
	}, {
		value: "510700",
		text: "绵阳市"
	}, {
		value: "510800",
		text: "广元市"
	}, {
		value: "510900",
		text: "遂宁市"
	}, {
		value: "511000",
		text: "内江市"
	}, {
		value: "511100",
		text: "乐山市"
	}, {
		value: "511300",
		text: "南充市"
	}, {
		value: "511400",
		text: "眉山市"
	}, {
		value: "511500",
		text: "宜宾市"
	}, {
		value: "511600",
		text: "广安市"
	}, {
		value: "511700",
		text: "达州市"
	}, {
		value: "511800",
		text: "雅安市"
	}, {
		value: "511900",
		text: "巴中市"
	}, {
		value: "512000",
		text: "资阳市"
	}, {
		value: "513200",
		text: "阿坝藏族羌族自治州"
	}, {
		value: "513300",
		text: "甘孜藏族自治州"
	}, {
		value: "513400",
		text: "凉山彝族自治州"
	}]
}, {
	value: '520000',
	text: '贵州省',
	children: [{
		value: "520100",
		text: "贵阳市"
	}, {
		value: "520200",
		text: "六盘水市"
	}, {
		value: "520300",
		text: "遵义市"
	}, {
		value: "520400",
		text: "安顺市"
	}, {
		value: "522200",
		text: "铜仁地区"
	}, {
		value: "522300",
		text: "黔西南布依族苗族自治州"
	}, {
		value: "522400",
		text: "毕节地区"
	}, {
		value: "522600",
		text: "黔东南苗族侗族自治州"
	}, {
		value: "522700",
		text: "黔南布依族苗族自治州"
	}]
}, {
	value: '530000',
	text: '云南省',
	children: [{
		value: "530100",
		text: "昆明市"
	}, {
		value: "530300",
		text: "曲靖市"
	}, {
		value: "530400",
		text: "玉溪市"
	}, {
		value: "530500",
		text: "保山市"
	}, {
		value: "530600",
		text: "昭通市"
	}, {
		value: "530700",
		text: "丽江市"
	}, {
		value: "530800",
		text: "普洱市"
	}, {
		value: "530900",
		text: "临沧市"
	}, {
		value: "532300",
		text: "楚雄彝族自治州"
	}, {
		value: "532500",
		text: "红河哈尼族彝族自治州"
	}, {
		value: "532600",
		text: "文山壮族苗族自治州"
	}, {
		value: "532800",
		text: "西双版纳傣族自治州"
	}, {
		value: "532900",
		text: "大理白族自治州"
	}, {
		value: "533100",
		text: "德宏傣族景颇族自治州"
	}, {
		value: "533300",
		text: "怒江傈僳族自治州"
	}, {
		value: "533400",
		text: "迪庆藏族自治州"
	}]
}, {
	value: '540000',
	text: '西藏',
	children: [{
		value: "540100",
		text: "拉萨市"
	}, {
		value: "542100",
		text: "昌都地区"
	}, {
		value: "542200",
		text: "山南地区"
	}, {
		value: "542300",
		text: "日喀则地区"
	}, {
		value: "542400",
		text: "那曲地区"
	}, {
		value: "542500",
		text: "阿里地区"
	}, {
		value: "542600",
		text: "林芝地区"
	}]
}, {
	value: '610000',
	text: '陕西省',
	children: [{
		value: "610100",
		text: "西安市"
	}, {
		value: "610200",
		text: "铜川市"
	}, {
		value: "610300",
		text: "宝鸡市"
	}, {
		value: "610400",
		text: "咸阳市"
	}, {
		value: "610500",
		text: "渭南市"
	}, {
		value: "610600",
		text: "延安市"
	}, {
		value: "610700",
		text: "汉中市"
	}, {
		value: "610800",
		text: "榆林市"
	}, {
		value: "610900",
		text: "安康市"
	}, {
		value: "611000",
		text: "商洛市"
	}]
}, {
	value: '620000',
	text: '甘肃省',
	children: [{
		value: "620100",
		text: "兰州市"
	}, {
		value: "620200",
		text: "嘉峪关市"
	}, {
		value: "620300",
		text: "金昌市"
	}, {
		value: "620400",
		text: "白银市"
	}, {
		value: "620500",
		text: "天水市"
	}, {
		value: "620600",
		text: "武威市"
	}, {
		value: "620700",
		text: "张掖市"
	}, {
		value: "620800",
		text: "平凉市"
	}, {
		value: "620900",
		text: "酒泉市"
	}, {
		value: "621000",
		text: "庆阳市"
	}, {
		value: "621100",
		text: "定西市"
	}, {
		value: "621200",
		text: "陇南市"
	}, {
		value: "622900",
		text: "临夏回族自治州"
	}, {
		value: "623000",
		text: "甘南藏族自治州"
	}]
}, {
	value: '630000',
	text: '青海省',
	children: [{
		value: "630100",
		text: "西宁市"
	}, {
		value: "632100",
		text: "海东地区"
	}, {
		value: "632200",
		text: "海北藏族自治州"
	}, {
		value: "632300",
		text: "黄南藏族自治州"
	}, {
		value: "632500",
		text: "海南藏族自治州"
	}, {
		value: "632600",
		text: "果洛藏族自治州"
	}, {
		value: "632700",
		text: "玉树藏族自治州"
	}, {
		value: "632800",
		text: "海西蒙古族藏族自治州"
	}]
}, {
	value: '640000',
	text: '宁夏',
	children: [{
		value: "640100",
		text: "银川市"
	}, {
		value: "640200",
		text: "石嘴山市"
	}, {
		value: "640300",
		text: "吴忠市"
	}, {
		value: "640400",
		text: "固原市"
	}, {
		value: "640500",
		text: "中卫市"
	}]
}, {
	value: '650000',
	text: '新疆',
	children: [{
		value: "650100",
		text: "乌鲁木齐市"
	}, {
		value: "650200",
		text: "克拉玛依市"
	}, {
		value: "652100",
		text: "吐鲁番地区"
	}, {
		value: "652200",
		text: "哈密地区"
	}, {
		value: "652300",
		text: "昌吉回族自治州"
	}, {
		value: "652700",
		text: "博尔塔拉蒙古自治州"
	}, {
		value: "652800",
		text: "巴音郭楞蒙古自治州"
	}, {
		value: "652900",
		text: "阿克苏地区"
	}, {
		value: "653000",
		text: "克孜勒苏柯尔克孜自治州"
	}, {
		value: "653100",
		text: "喀什地区"
	}, {
		value: "653200",
		text: "和田地区"
	}, {
		value: "654000",
		text: "伊犁哈萨克自治州"
	}, {
		value: "654200",
		text: "塔城地区"
	}, {
		value: "654300",
		text: "阿勒泰地区"
	}, {
		value: "659001",
		text: "石河子市"
	}, {
		value: "659002",
		text: "阿拉尔市"
	}, {
		value: "659003",
		text: "图木舒克市"
	}, {
		value: "659004",
		text: "五家渠市"
	}]
}, {
	value: '710000',
	text: '台湾省',
	children: [{
		value: "710100",
		text: "台北市"
	}, {
		value: "710200",
		text: "高雄市"
	}, {
		value: "710300",
		text: "台南市"
	}, {
		value: "710400",
		text: "台中市"
	}, {
		value: "710500",
		text: "金门县"
	}, {
		value: "710600",
		text: "南投县"
	}, {
		value: "710700",
		text: "基隆市"
	}, {
		value: "710800",
		text: "新竹市"
	}, {
		value: "710900",
		text: "嘉义市"
	}, {
		value: "711100",
		text: "新北市"
	}, {
		value: "711200",
		text: "宜兰县"
	}, {
		value: "711300",
		text: "新竹县"
	}, {
		value: "711400",
		text: "桃园县"
	}, {
		value: "711500",
		text: "苗栗县"
	}, {
		value: "711700",
		text: "彰化县"
	}, {
		value: "711900",
		text: "嘉义县"
	}, {
		value: "712100",
		text: "云林县"
	}, {
		value: "712400",
		text: "屏东县"
	}, {
		value: "712500",
		text: "台东县"
	}, {
		value: "712600",
		text: "花莲县"
	}, {
		value: "712700",
		text: "澎湖县"
	}]
}, {
	value: '810000',
	text: '香港',
	children: [{
		value: "810100",
		text: "香港岛"
	}, {
		value: "810200",
		text: "九龙"
	}, {
		value: "810300",
		text: "新界"
	}]
}, {
	value: '820000',
	text: '澳门',
	children: [{
		value: "820100",
		text: "澳门半岛"
	}, {
		value: "820200",
		text: "离岛"
	}]
}, {
	value: '990000',
	text: '海外',
	children: [{
		value: "990100",
		text: "海外"
	}]
}]

export default cityData