<template>
  <div class="AboutUs">
    <div class="demo-slider-area slider-area bg-transparent slider-style-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center display-two">
              <h1
                class="title mt--130 mb--50"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                万合智联（浙江）科技有限公司
              </h1>
              <br />
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                合世界 ● 联未来
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
      <div class="container">
        <div class="introduce">
          <div class="centerContent row">
            <div class="introduceLeft col-md-6 col-12">
              <img
                src="https://images.aiwork.cloud/website/logo1@2x.png"
                alt="vanhe万合智联"
              />
              <hr />
              <p>品牌Slogan：合世界·联未来</p>
              <p>发展愿景：数字化地产科技综合服务平台</p>
              <p>企业使命：科技赋能、增效降本、节能创收</p>
              <p>企业价值观：持续创新、价值共享</p>
            </div>
            <ul class="introduceRight col-md-6 col-12">
              <li>
                万合智联（浙江）科技有限公司 ，
                作为万合控股旗下地产科技创新平台，自主技术团队研发领先全行业的数字化能效管理系统（智能电表、智能中央空调远程集控等），致力于推动与地方政府、社会伙伴就地产数字化运营展开合作，共赢共享数字化时代红利。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30"
      style="background-color: #f9fbff"
    >
      <div class="container">
        <div class="us2">
          <Title title="联系我们" translate="Contact Us" />
          <div class="row row--0">
            <a
              class="col-md-8 col-12"
              href="https://ditu.amap.com/place/B0FFM1G09V"
              target="_blank"
            >
              <img
                src="https://images.aiwork.cloud/website/Group 34@2x.png"
                alt="万合智联地图位置"
            /></a>
            <div class="contactInformation col-md-4 col-bg col-12">
              <div style="margin-bottom: 32px">
                <div class="concentricCircle"></div>
                <h3>联系方式</h3>
              </div>
              <p>电话：0571-86825558</p>
              <p>邮箱：vanhebiz@163.com</p>
              <p>传真：057189993733</p>
              <p>地址：浙江省杭州市钱塘区下沙街道湖畔中心A座19楼</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../../components/elements/title/Title.vue";
export default {
  components: {
    Title,
  },
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.AboutUs {
  .wrapper {
    overflow: hidden;
    .us2 {
      margin-bottom: 40px;
    }
    .contactInformation {
      padding: 47px 32px;
      background-color: #ffffff;
      h3 {
        font-size: 24px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 28px;
        letter-spacing: 1px;
        display: inline-block;
      }
      p {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }
    }

    .introduce > .centerContent {
      display: flex;
      padding: 65px 0;

      justify-content: space-between;
      background-color: #ffffff;
    }

    .introduceLeft > img {
      width: 200px;
      height: 24px;
      display: block;
    }

    .introduceLeft > hr {
      width: 160px;
      height: 2px;
      background: #3776ff;
      border-radius: 2px 2px 2px 2px;
      display: inline-block;
      margin-top: 32px;
      margin-bottom: 20px;
    }

    .introduceRight {
      // width: 630px;
      height: 300px;
      font-size: 24px;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      color: #666666;
      line-height: 50px;
      letter-spacing: 1px;
    }

    .introduceRight li {
      list-style-type: disc;
    }

    .introduceLeft > p {
      font-size: 24px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      letter-spacing: 1px;
    }

    .concentricCircle {
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: 1px solid #3776ff;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: -2px;
      margin-right: 5px;
      &::after {
        position: absolute;
        top: 3px;
        left: 3px;
        margin: auto;
        content: "";
        width: 12px;
        height: 12px;
        background: #3776ff;
        border-radius: 6px;
        box-sizing: border-box;
      }
    }
    // .primary .ContactUs {
    //   background-color: #f9fbff;
    //   overflow: hidden;
    // }
  }
  .demo-slider-area {
    background-image: url("https://images.aiwork.cloud/website/banner_women@2x.png");
    background-size: 1920px 500px;
    background-color: #1b3f64 !important;
    background-repeat: no-repeat;
    background-position: center;
    color: #ffffff;
    height: 500px;
    .inner {
      text-align: end !important;
      h1 {
        font-size: 1em;
        color: #ffffff !important;
      }
      .brandPositioning {
        display: inline-block;
        background-color: var(--color-primary);
        height: 50px;
        border-radius: 4px 4px 4px 4px;
        line-height: 50px;
        text-align: center;
        font-size: 0.6em;
        margin-bottom: 24px;
        padding: 0 0.66em;
      }
      .description {
        display: inline-block;
        color: #ffffff !important;
        padding: 0;
        margin-bottom: 72px;
        font-size: 0.6em;
        background: var(--color-primary);

        border-radius: 4px 4px 4px 4px;
        padding: 9px 25px;
      }
      .bookingSystemDemonstration {
        display: inline-block;
        height: 56px;
        line-height: 56px;
        border-radius: 70px 70px 70px 70px;
        border: 1px solid #ffffff;
        text-align: center;
        margin-bottom: 153px;
        font-size: 0.4em;
        padding: 0 1.3em;
      }
    }
  }
}
</style>
