import {
  defineStore
} from 'pinia'

// 创建store,命名规则： useXxxxStore
// 参数1：store的唯一表示
// 参数2：对象，可以提供state actions getters

export const useStore = defineStore('counter', {
  // data里中的数据
  state: () => ({
    activeIndex: 0
  }),
  // 计算属性
  getters: {
    // double: state => state.count * 2
  },
  // 相当于 vue中的 methods 既可以写同步代码也可以写异步
  actions: {
    changeActiveIndex(index) {
      console.log('触发', index);
      this.activeIndex = index
    }
  }
})