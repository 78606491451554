<template>
  <div class="VanheCard">
    <div class="row row--0 col-12 mb--90">
      <img
        class="col-lg-6 col-12 order-1"
        :src="i.img"
        data-aos="fade-down"
        :data-aos-delay="100 + index"
      />
      <div
        :class="{
          'col-lg-6': true,
          'col-12': true,
          content: true,
          'order-lg-2': index % 2 === 0,
        }"
        :style="{
          backgroundImage:
            'url(' + `https://images.aiwork.cloud/website/0${i.id}@2x.png` + ')',
        }"
        data-aos="fade-up"
        :data-aos-delay="100 + index"
      >
        <h3>
          <span class="concentricCircle" v-if="index % 2 === 0" />{{ i.title
          }}<span
            class="concentricCircle concentricCircle1"
            v-if="index % 2 === 1"
          />
        </h3>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    i: {
      type: Object,
      default: {},
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.VanheCard {
  background-color: #f9fbff;
  p {
    color: #666666;
  }
  .content {
    background-size: 50%;
    background-repeat: no-repeat;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 5%;
    justify-content: space-around;

    align-items: flex-end;

    h3 {
      font-size: 24px;
      font-weight: 400;
      color: #000000;
      line-height: 28px;
    }

    .concentricCircle {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid var(--color-primary);
      vertical-align: middle;
      border-radius: 10px;
      box-sizing: border-box;
      margin-right: 10px;

      position: relative;
    }

    .concentricCircle1 {
      margin-right: 0;
      margin-left: 10px;
    }
    .concentricCircle::after {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: var(--color-primary);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
    }
  }

  .order-lg-2 {
    background-position: 100% 0%;
    align-items: flex-start;
  }
}
</style>
