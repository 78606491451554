<template>
  <div class="MarketPainPoints">
    <div class="demo-slider-area slider-area bg-transparent slider-style-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center display-two">
              <h1
                class="title mt--130 mb--50"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                赋能园区数字化运营和管理
              </h1>
              <br />
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                ● 业务流式设计 ● 智慧物业 ● 园区物联<br />
                ● 适用多场景 ● 增值服务 ● 降本增效
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
      <Title
        title="VHIC数智能效管理系统"
        translate="VanHe Intelligent Connection"
      />
      <div class="row row--0 col-12">
        <div class="product-item col-lg-3 col-md-6 col-12">
          <img src="https://images.aiwork.cloud/website/wuyeshuju@2x.png" alt="" />
          <h3>物业管理数据总览</h3>
          <div class="separate">
            <div class="line"></div>
            <div class="concentricCircle"></div>
            <div class="line"></div>
          </div>
          <ul>
            <li><span>·</span> 业务流式工单处理</li>
            <li><span>·</span> 能效数据实时上传</li>
            <li><span>·</span> 设备报警实时上报</li>
          </ul>
        </div>
        <div class="product-item col-lg-3 col-md-6 col-12">
          <img src="https://images.aiwork.cloud/website/zuhushebei@2x.png" alt="" />
          <h3>租户设备总控</h3>
          <div class="separate">
            <div class="line"></div>
            <div class="concentricCircle"></div>
            <div class="line"></div>
          </div>
          <ul>
            <li><span>·</span> 房源信息模块化展示</li>
            <li><span>·</span> 房内设备状态一目了然</li>
            <li><span>·</span> 房源出租状态一目了然</li>
          </ul>
        </div>
        <div class="product-item col-lg-3 col-md-6 col-12">
          <img class="yidong" src="https://images.aiwork.cloud/website/zuhuyidong@2x.png" alt="" />
          <h3>租户移动端</h3>
          <div class="separate">
            <div class="line"></div>
            <div class="concentricCircle"></div>
            <div class="line"></div>
          </div>
          <ul>
            <li><span>·</span> 增值服务，降低租户办公运营成本</li>
            <li><span>·</span> 增值服务收益共享，降本增效</li>
          </ul>
        </div>
        <div class="product-item col-lg-3 col-md-6 col-12">
          <img
            class="yidong"
            src="https://images.aiwork.cloud/website/zuhushebei@2x (1).png"
            alt=""
          />
          <h3>租户移动端设备控制</h3>
          <div class="separate">
            <div class="line"></div>
            <div class="concentricCircle"></div>
            <div class="line"></div>
          </div>
          <ul>
            <li><span>·</span> 远程控制能源设备</li>
            <li><span>·</span> 线上充值能源帐户，每日计费扣款</li>
            <li><span>·</span> 表计数据、账单实时查看</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VanheCard from "../../components/custom/VanheCard.vue";
import Title from "../../components/elements/title/Title.vue";
export default {
  components: {
    VanheCard,
    Title,
  },
  data: function () {
    return {
      painList: [
        {
          id: 1,
          img: "https://images.aiwork.cloud/website/yewuyuan@2x.png",
          title: "业务员私下收租金为租客办理入住并卷款跑路",
          details: [
            "系统空置走电功能，设置每个房间每日最高用电限额（n kw·h），每日超出用电限度后电表自动跳闸，次日会自动开闸。 ",
            "财务审核流程，若未获取到钱帐一致审核信息，房租账单逾期、能源账户欠费，将跳闸、锁门禁等操作强制租户结清才可使用。",
          ],
        },
        {
          id: 2,
          img: "https://images.aiwork.cloud/website/zuhu@2x.png",
          title: "租户太多，房租账单忘记收款",
          details: [
            "合同线上签约功能，自动捕捉合同信息。每期房租付款日提前提醒租客缴费，并创建为工单提醒物业关注缴费情况。",
            "自动形成账单各状态分析报表。使物业及时关注逾期账单。",
          ],
        },
        {
          id: 3,
          img: "https://images.aiwork.cloud/website/yunyingfnag@2x.png",
          title: "运营方对入住园区的公司或个人信息保护需求",
          details: [
            "独立部署系统，园区租户信息保护功能。",
            "第三方评测机构对整套系统检测，并出据检测证书。",
          ],
        },
        {
          id: 4,
          img: "https://images.aiwork.cloud/website/xitong@2x.png",
          title: "系统建设成本",
          details: [
            "万合智联公司为园区提供增值服务功能，广告、商标品牌注册、公司注册、饮用水、绿植、共享行政财务人事等服务收益与园区运营方共享。",
            "根据园区规模进行前期硬件设备减免实施，降本增效。",
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.MarketPainPoints {
  background-color: #f9fbff;
  overflow: hidden;
  .demo-slider-area {
    background-image: url("https://images.aiwork.cloud/website/banner_shicang@2x.png");
    background-size: 1920px 500px;
    background-color: #1b3f64 !important;
    background-repeat: no-repeat;
    background-position: center;
    color: #ffffff;
    margin-bottom: 40px;
    height: 500px;
    .inner {
      text-align: end !important;
      h1 {
        font-size: 1em;
        color: #ffffff !important;
      }
      .brandPositioning {
        display: inline-block;
        background-color: var(--color-primary);
        height: 50px;
        border-radius: 4px 4px 4px 4px;
        line-height: 50px;
        text-align: center;
        font-size: 0.6em;
        margin-bottom: 24px;
        padding: 0 0.66em;
      }
      .description {
        display: inline-block;
        color: #ffffff !important;
        padding: 0;
        margin-bottom: 72px;
        font-size: 0.6em;
        background: var(--color-primary);

        border-radius: 4px 4px 4px 4px;
        padding: 9px 25px;
      }
      .bookingSystemDemonstration {
        display: inline-block;
        height: 56px;
        line-height: 56px;
        border-radius: 70px 70px 70px 70px;
        border: 1px solid #ffffff;
        text-align: center;
        margin-bottom: 153px;
        font-size: 0.4em;
        padding: 0 1.3em;
      }
    }
  }
  .describeDetails {
    margin-top: 50px;
    list-style-type: none;
    li {
      font-size: 16px;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      color: #666666;
      line-height: 40px;
      span {
        font-size: 50px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  .describeDetails.desRight {
    text-align: right;
  }

  .product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 176px;
      height: 140px;
    }
    .yidong {
      width: 75px;
      height: 150px;
      margin-top: -10px;
    }
    h3 {
      margin-top: 36px;
      margin-bottom: 10px;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 21px;
    }
    ul {
      margin-bottom: 84px;
      padding-left: 0px;
      list-style-type: none;
      li {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei-Light, Microsoft YaHei;
        font-weight: 300;
        color: #666666;
        line-height: 32px;
        span {
          font-size: 40px;
          vertical-align: middle;
        }
      }
    }
    .separate {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .line {
        width: 55px;
        height: 2px;
        background: #3776ff;
        border-radius: 2px 2px 2px 2px;
      }
      .concentricCircle {
        position: relative;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        border: 1px solid #3776ff;
        display: inline-block;
        box-sizing: border-box;
        margin: 0 12px;
        &::after {
          position: absolute;
          top: 3px;
          left: 3px;
          margin: auto;
          content: "";
          width: 6px;
          height: 6px;
          background: #3776ff;
          border-radius: 3px;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
