var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"VanheCard"},[_c('div',{staticClass:"row row--0 col-12 mb--90"},[_c('img',{staticClass:"col-lg-6 col-12 order-1",attrs:{"src":_vm.i.img,"data-aos":"fade-down","data-aos-delay":100 + _vm.index}}),_c('div',{class:{
        'col-lg-6': true,
        'col-12': true,
        content: true,
        'order-lg-2': _vm.index % 2 === 0,
      },style:({
        backgroundImage:
          'url(' + `https://images.aiwork.cloud/website/0${_vm.i.id}@2x.png` + ')',
      }),attrs:{"data-aos":"fade-up","data-aos-delay":100 + _vm.index}},[_c('h3',[(_vm.index % 2 === 0)?_c('span',{staticClass:"concentricCircle"}):_vm._e(),_vm._v(_vm._s(_vm.i.title)),(_vm.index % 2 === 1)?_c('span',{staticClass:"concentricCircle concentricCircle1"}):_vm._e()]),_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }