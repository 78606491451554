<template>
  <div class="MarketPainPoints">
    <div class="demo-slider-area slider-area bg-transparent slider-style-1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center display-two">
              <h1
                class="title mt--130 mb--50"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                解决多维度问题
              </h1>
              <br />
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                ● 智慧园区 ● 智慧物业 ● 园区物联
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
      <VanheCard
        v-for="(i, index) in painList"
        :i="i"
        :index="index"
        :key="i.id"
      >
        <ul :class="{ describeDetails: true, desRight: index % 2 === 1 }">
          <li v-for="(j, jndex) in i.details" :key="jndex">
            <span>·</span>{{ j }}
          </li>
        </ul>
      </VanheCard>
    </div>
  </div>
</template>

<script>
import VanheCard from "../../components/custom/VanheCard.vue";
export default {
  components: {
    VanheCard,
  },
  data: function () {
    return {
      painList: [
        {
          id: 1,
          img: "https://images.aiwork.cloud/website/yewuyuan@2x.png",
          title: "业务员私下收租金为租客办理入住并卷款跑路",
          details: [
            "系统空置走电功能，设置每个房间每日最高用电限额（n kw·h），每日超出用电限度后电表自动跳闸，次日会自动开闸。 ",
            "财务审核流程，若未获取到钱帐一致审核信息，房租账单逾期、能源账户欠费，将跳闸、锁门禁等操作强制租户结清才可使用。",
          ],
        },
        {
          id: 2,
          img: "https://images.aiwork.cloud/website/zuhu@2x.png",
          title: "租户太多，房租账单忘记收款",
          details: [
            "合同线上签约功能，自动捕捉合同信息。每期房租付款日提前提醒租客缴费，并创建为工单提醒物业关注缴费情况。",
            "自动形成账单各状态分析报表。使物业及时关注逾期账单。",
          ],
        },
        {
          id: 3,
          img: "https://images.aiwork.cloud/website/yunyingfnag@2x.png",
          title: "运营方对入住园区的公司或个人信息保护需求",
          details: [
            "独立部署系统，园区租户信息保护功能。",
            "第三方评测机构对整套系统检测，并出据检测证书。",
          ],
        },
        {
          id: 4,
          img: "https://images.aiwork.cloud/website/xitong@2x.png",
          title: "系统建设成本",
          details: [
            "万合智联公司为园区提供增值服务功能，广告、商标品牌注册、公司注册、饮用水、绿植、共享行政财务人事等服务收益与园区运营方共享。",
            "根据园区规模进行前期硬件设备减免实施，降本增效。",
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.MarketPainPoints {
  background-color: #f9fbff;
  overflow: hidden;
  .demo-slider-area {
    background-image: url("https://images.aiwork.cloud/website/banner_shicang@2x.png");
    background-size: 1920px 500px;
    background-color: #1b3f64 !important;
    background-repeat: no-repeat;
    background-position: center;
    color: #ffffff;
    margin-bottom: 90px;
    height: 500px;
    .inner {
      text-align: end !important;
      h1 {
        font-size: 1em;
        color: #ffffff !important;
      }
      .brandPositioning {
        display: inline-block;
        background-color: var(--color-primary);
        height: 50px;
        border-radius: 4px 4px 4px 4px;
        line-height: 50px;
        text-align: center;
        font-size: 0.6em;
        margin-bottom: 24px;
        padding: 0 0.66em;
      }
      .description {
        display: inline-block;
        color: #ffffff !important;
        padding: 0;
        margin-bottom: 72px;
        font-size: 0.6em;
        background: var(--color-primary);

        border-radius: 4px 4px 4px 4px;
        padding: 9px 25px;
      }
      .bookingSystemDemonstration {
        display: inline-block;
        height: 56px;
        line-height: 56px;
        border-radius: 70px 70px 70px 70px;
        border: 1px solid #ffffff;
        text-align: center;
        margin-bottom: 153px;
        font-size: 0.4em;
        padding: 0 1.3em;
      }
    }
  }
  .describeDetails {
    margin-top: 50px;
    list-style-type: none;
    li {
      font-size: 16px;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      color: #666666;
      line-height: 40px;
      span {
        font-size: 50px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  .describeDetails.desRight {
    text-align: right;
  }
}
</style>
