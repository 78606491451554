<template>
  <ul class="mainmenu">
    <li>
      <router-link
        to="/"
        :class="{ active: activeIndex === 0 }"
        @click.native="changeActiveIndex(0)"
        >首页</router-link
      >
    </li>
    <li>
      <router-link
        to="/MarketPainPoints"
        :class="{ active: activeIndex === 1 }"
        @click.native="changeActiveIndex(1)"
        >市场痛点</router-link
      >
    </li>
    <li>
      <router-link
        to="/Product"
        :class="{ active: activeIndex === 2 }"
        @click.native="changeActiveIndex(2)"
        >产品与解决方案</router-link
      >
    </li>
    <li>
      <router-link
        to="/Service"
        :class="{ active: activeIndex === 3 }"
        @click.native="changeActiveIndex(3)"
        >服务案例</router-link
      >
    </li>
    <li>
      <router-link
        to="/AboutUs"
        :class="{ active: activeIndex === 4 }"
        @click.native="changeActiveIndex(4)"
        >关于我们</router-link
      >
    </li>
    <!-- <li class="with-megamenu has-menu-child-item">
      <a
        href="#"
        :class="{ active: activeIndex === 5 }"
        @click="changeActiveIndex(5)"
      >
        Elements
        <Icon name="plus" class="collapse-icon plus" />
        <Icon name="minus" class="collapse-icon minus" />
      </a>
      <div class="rn-megamenu">
        <div class="wrapper">
          <div class="row row--0">
            <div class="col-lg-3 single-mega-item">
              <ul class="mega-menu-item">
                <li><router-link to="/button">Button</router-link></li>
                <li><router-link to="/service">Service</router-link></li>
                <li><router-link to="/counter">Counter</router-link></li>
                <li>
                  <router-link to="/progressbar">Progressbar</router-link>
                </li>
                <li><router-link to="/accordion">Accordion</router-link></li>
                <li>
                  <router-link to="/social-share">Social Share</router-link>
                </li>
                <li><router-link to="/blog-grid">Blog Grid</router-link></li>
              </ul>
            </div>
            <div class="col-lg-3 single-mega-item">
              <ul class="mega-menu-item">
                <li><router-link to="/team">Team</router-link></li>
                <li><router-link to="/portfolio">Portfolio</router-link></li>
                <li>
                  <router-link to="/testimonial">Testimonial</router-link>
                </li>
                <li><router-link to="/timeline">Timeline</router-link></li>
                <li><router-link to="/tab">Tab</router-link></li>
                <li><router-link to="/pricing">Pricing</router-link></li>
                <li>
                  <router-link to="/spit-section">Split Section</router-link>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 single-mega-item">
              <ul class="mega-menu-item">
                <li>
                  <router-link to="/call-to-action">Call To Action</router-link>
                </li>
                <li><router-link to="/video">Video</router-link></li>
                <li><router-link to="/gallery">Gallery</router-link></li>
                <li><router-link to="/contact">Contact</router-link></li>
                <li><router-link to="/brand">Brand</router-link></li>
                <li><router-link to="/portfolio">Portfolio</router-link></li>
                <li><router-link to="/error">404</router-link></li>
              </ul>
            </div>
            <div class="col-lg-3 single-mega-item">
              <ul class="mega-menu-item">
                <li>
                  <router-link to="/advance-tab">
                    Advance Tab <span class="rn-badge-card">Hot</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="#">
                    Brand Carousel <span class="rn-badge-card">Comming</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/advance-pricing">
                    Advance Pricing <span class="rn-badge-card">Hot</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/portfolio-details/1">
                    Portfolio Details
                  </router-link>
                </li>
                <li>
                  <router-link to="/blog-details/1"> Blog Details </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li> -->
  </ul>
</template>

<script>
import Icon from "../../icon/Icon";
import { mapState, mapActions } from "pinia";
import { useStore } from "@/store";
export default {
  name: "Nav",
  components: { Icon },
  computed: {
    ...mapState(useStore, ["activeIndex"]),
  },

  mounted() {
    console.log(this.$route.meta.index);
    this.changeActiveIndex(this.$route.meta.index || 0);
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(useStore, ["changeActiveIndex"]),
  },
};
</script>
<style scoped>
a{
  color: black !important;;
}
</style>