<template>
  <transition name="dialog-transition">
    <div class="dialog-mask" v-show="show">
      <div class="custom-dialog">
        <img
          src="https://images.aiwork.cloud/website/guanbi@2x.png"
          alt="关闭预约产品演示弹窗"
          @click="closeModal"
        />
        <h3>预约产品演示</h3>
        <p>请完善以下信息，专属业务顾问将为您安排功能演示介绍</p>
        <form>
          <div class="formItem">
            <div class="formItem-label"><span>*</span>称呼</div>
            <input
              type="text"
              name="name"
              placeholder="请输入您的称呼"
              style="width: 0; margin-right: 8px"
              v-model="form.name"
            />
            <div class="radios">
              <input
                class="radio_type"
                type="radio"
                name="gender"
                checked
                @click="form.gender = 1"
              />
              <span>先生</span>
              <input
                class="radio_type"
                type="radio"
                name="gender"
                style="margin-left: 16px"
                @click="form.gender = 2"
              />
              <span>女士</span>
            </div>
          </div>
          <div class="formItem">
            <div class="formItem-label">项目名称</div>
            <input
              type="text"
              name="project"
              v-model="form.project"
              placeholder="请输入贵单位项目"
            />
          </div>
          <div class="formItem">
            <div class="formItem-label">地区</div>
            <select id="province" name="province" v-model="province_index">
              <option value="" disabled selected>选择省份</option>
              <option
                v-for="(i, index) in cityData"
                :key="i.value"
                :value="index"
              >
                {{ i.text }}
              </option>
            </select>
            <select
              id="city"
              name="city"
              v-model="form.city"
              style="margin-right: 0px"
            >
              <option value="" disabled selected>选择城市</option>
              <option v-for="i in city2Data" :key="i.value" :value="i.text">
                {{ i.text }}
              </option>
            </select>
          </div>
          <div class="formItem">
            <div class="formItem-label"><span>*</span>手机号码</div>
            <input
              type="text"
              name="mobile"
              v-model="form.mobile"
              placeholder="请输入您的手机号码"
            />
          </div>
          <div class="formItem">
            <div class="formItem-label"><span>*</span>图形验证码</div>
            <input
              type="text"
              id="imgcode"
              name="captcha"
              v-model="form.captcha"
              placeholder="请输入请输入图形验证码"
              style="padding-right: 120px"
            />
            <img id="theimgcode" :src="theimgurl" alt="" @click="getImgCode" />
          </div>
          <div class="formItem">
            <div class="formItem-label"><span>*</span>短信验证码</div>
            <input
              type="text"
              name="sms_code"
              v-model="form.sms_code"
              placeholder="请输入请输入短信验证码"
              style="margin-right: 8px"
            />
            <div
              class="verificationCode"
              :class="{ disable: textContent !== '获取验证码' }"
              @click="verificationCodeCountdown"
            >
              {{ textContent }}
            </div>
          </div>

          <div class="formItem" style="align-items: start">
            <div class="formItem-label">备注</div>
            <textarea
              name="desc"
              id="remarks"
              v-model="form.desc"
              rows="4"
              placeholder="请描述您的其他需求（非必填）"
            ></textarea>
          </div>
        </form>
        <button type="submit" class="submit" @click="submitAppointment">
          提交预约申请
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import cityData from "../../helpers/city.data";

let interval = null;
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      theimgurl: null,
      textContent: "获取验证码",
      form: {
        gender: 1,
      },
      cityData: cityData,
      province_index: null,
    };
  },
  computed: {
    city2Data() {
      if (!this.cityData[this.province_index]) {
        return false;
      }

      return this.cityData[this.province_index].children || [];
    },
  },
  watch: {
    province_index(newV) {
      this.form.province = this.cityData[newV].text;
    },
    show(newV) {
      if (newV) {
        this.getImgCode();
        this.handleScreenAuto();
        window.onresize = () => this.handleScreenAuto();
      } else {
        window.onresize = null;
      }
    },
  },
  methods: {
    handleScreenAuto() {
      const designDraftWidth = 540 * 1.25; //设计稿的宽度
      const designDraftHeight = 620 * 1.25; //设计稿的高度
      // 根据屏幕的变化适配的比例
      const scale = window.innerWidth / window.innerHeight < designDraftWidth / designDraftHeight ? window.innerWidth / designDraftWidth : window.innerHeight / designDraftHeight;
      // 缩放比例
      console.log(scale);
      document.getElementsByClassName(
        "custom-dialog"
      )[0].style.transform = `scale(${scale})`;
    },
    closeModal() {
      this.$emit("update:show", false);
    },
    getImgCode(e) {
      axios
        .get("https://energy-api.aiwork.cloud/api/energy/utils/captcha")
        .then((response) => {
          if (response.data.code === "000000") {
            // const theimgcode = document.getElementById("theimgcode");
            // theimgcode.setAttribute("src", response.data.data.b64s);

            console.log(response.data.data.b64s);
            this.theimgurl = response.data.data.b64s;
            this.form.id = response.data.data.id;
            this.form.captcha = "";
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (err) {
          // console.error(err);
        });

      if (e) {
        e.stopPropagation();
      }
    },
    verificationCodeCountdown() {
      if (interval !== null) {
        return false;
      }
      const { mobile, captcha, id } = this.form;

      if (!(mobile && captcha && id)) {
        alert("请输入手机号以及验证码");
        return false;
      }

      axios
        .post(
          "https://energy-api.aiwork.cloud/api/energy/utils/smsForOfficalAppointment",
          {
            mobile,
            id,
            captcha,
          }
        )
        .then((response) => {
          if (response.data.code === "000000") {
            let countdown = 60 * 10;
            this.textContent = countdown + " s";
            interval = setInterval(() => {
              countdown--;
              this.textContent = countdown + " s";
              if (countdown <= 0) {
                this.textContent = "获取验证码";
                clearInterval(interval);
                interval = null;
              }
            }, 1000);
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    },
    submitAppointment() {
      console.log(this.form);

      axios
        .post(
          "https://energy-api.aiwork.cloud/api/energy/utils/addAppointmentData",
          this.form
        )
        .then(function (response) {
          console.log(response.data);
          if (response.data.code === "000000") {
            alert("预约成功");
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-transition {
  &-enter-active,
  &-leave-active {
    transition: all 0.5s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
.dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000050;
  z-index: 2;
}
.custom-dialog {
  width: 540px;
  height: 620px;
  position: absolute;
  background-color: #ffffff;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: opacity 1s;
  border-radius: 8px;
  box-sizing: border-box;
  padding-top: 22px;
  padding-left: 22px;
  padding-right: 22px;

  & > img {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 24px;
    cursor: pointer;
  }

  & > h3 {
    text-align: center;
    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 23px;
    margin-bottom: 14px;
  }

  & > p {
    text-align: center;

    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin-bottom: 22px;
  }

  & .submit {
    width: 230px;
    height: 40px;
    background: #3776ff;
    border-radius: 70px 70px 70px 70px;

    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    border: 0;
    cursor: pointer;
    margin: 0 auto;
    display: block;
  }

  form {
    .formItem {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      position: relative;
      span {
        vertical-align: -0.21rem;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
      }
    }
    img {
      width: 100px;
      height: 30px;
      position: absolute;
      right: 10px;
    }
    .formItem-label {
      width: 6em;
      height: 18px;
      text-align: end;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #737373;
      line-height: 16px;
      margin-right: 12px;
      & > span {
        color: #ee0000 !important;
      }
    }
    select {
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #cccccc;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      flex: 1;
      padding: 0 10px;
      height: 42px;

      margin-right: 8px;

      font-size: 12px;
      line-height: 14px;
    }
    option {
      font-size: 18px;
    }
    select:focus {
      outline: 1px solid #3776ff;
    }

    input {
      color: black !important;
    }
    textarea {
      color: black !important;
    }
    select {
      color: black !important;
    }

    input[type="text"] {
      // min-width: 375px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #cccccc;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      flex: 1;
      padding: 0 10px;
      height: 42px;

      font-size: 12px;
      line-height: 14px;
      &::-webkit-input-placeholder {
        font-size: 12px;
        font-family: Microsoft YaHei-Light, Microsoft YaHei;
        font-weight: 300;
        color: #999999;
        line-height: 14px;
      }
      &:focus {
        outline: 1px solid #3776ff;
      }
    }

    .verificationCode {
      width: 150px;
      height: 40px;
      background: #3776ff;
      border-radius: 6px 6px 6px 6px;
      text-align: center;

      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      cursor: pointer;
      user-select: none;
    }
    .verificationCode.disable {
      background-color: #cccccc;
    }
    textarea {
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #cccccc;
      font-size: 12px;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      line-height: 14px;
      flex: 1;
      padding: 12px 10px;
      resize: none;
      &:focus {
        outline: 1px solid #3776ff;
      }
    }
  }

  .radios {
    display: flex;
    align-items: center;
  }

  .radio_type {
    width: 16px;
    height: 16px;
    appearance: none;
    position: relative;
    vertical-align: middle;
    opacity: 1;
  }

  .radio_type:before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #7d7d7d;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    position: absolute;
  }

  .radio_type:checked:before {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #3776ff;
    background: #3776ff;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    position: absolute;
  }

  .radio_type:checked:after {
    content: "";
    width: 10px;
    height: 5px;
    border: 2px solid white;
    border-top: transparent;
    border-right: transparent;
    text-align: center;
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    transform: rotate(-45deg);
    position: absolute;
  }

  .radio_type:checked + label {
    color: #3776ff;
    position: absolute;
  }
}
</style>
